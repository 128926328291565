import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaMapMarkerAlt, FaEnvelope, FaFacebook, FaInstagram, FaWhatsapp, FaTelegramPlane } from 'react-icons/fa';
import logo from './img/Khapra law firm logo white 1.webp';
import face from './img/Group.webp';
import wa from './img/Group 2.webp';
import ins from './img/Group 4.webp';
import tt from './img/Group-1.webp';

import './css/Footer.css';
function Footer() {
    return (
        <footer style={{ backgroundColor: '#3A1907', color: '#fff', padding: '30px 0' }}>
            <Container>
                <Row className="text-center text-md-start footer-top-row">
                    {/* Logo and Description */}
                    <Col xs={12} md={3} className="mb-4">
                        <img src={logo} className='footer-logo-images' />
                        <h4 className='main-heading-footer'>
                            Khapra Law Firm is a full-service law firm, providing you an extensive range of legal service.
                        </h4>
                    </Col>

                    {/* Useful Links */}
                    <Col xs={12} md={3} className="mb-4">
                        <h5>USEFUL LINKS</h5>
                        <ul className="list-unstyled">
                            <li>About Us</li>
                            <li>EXPERTISE</li>
                            <li>Our Team</li>
                            <li>News And Insights</li>
                            <li>Careers</li>
                            <li>Contact Us</li>
                            <li>Privacy & Security</li>
                            <li>Terms Of Use</li>
                            <li>Cookie Policy</li>
                        </ul>
                    </Col>

                    {/* Contact Information */}
                    <Col xs={12} md={3} className="mb-4">
                        <h5>CONTACT US</h5>
                        <p>
                            <Row>
                                <Col md={1} className='p-0'>

                                    <FaPhone />
                                </Col>
                                <Col md={10}>

                                    +91 9911342966, +91 7850999966
                                </Col>
                            </Row>

                        </p>
                        <p>
                            <Row>
                                <Col className='p-0' md={1}>

                                    <FaMapMarkerAlt />
                                </Col>
                                <Col md={10}>

                                    Address: Khapra Law Firm, Chamber No. 335 Lawyers Chamber Block, Rohini Courts, Rohini Delhi - 110085
                                </Col>

                            </Row>
                        </p>
                        <p>
                            <Row>
                                <Col md={1} className='p-0'>

                                    <FaMapMarkerAlt />

                                </Col>
                                <Col md={10} >

                                    Office: Flat No. 76, Gulab Vihar Apartments, Sector-9, Rohini, Delhi - 110085
                                </Col>
                            </Row>



                        </p>
                        <p>
                            <Row>
                                <Col md={1} className='p-0'>
                                    <FaEnvelope />
                                </Col>
                                <Col md={10}>
                                    khapralawfirm@gmail.com
                                </Col>
                            </Row>


                        </p>


                    </Col>
                    {/* Contact Information */}
                    <Col xs={12} md={3} className="mt-4 social-colmn-footer">

                        {/* Social Media Icons */}
                        <div className="d-flex justify-content-center justify-content-md-start mt-3">
                            <a href="#" className="text-white me-3"><img src={face} /></a>
                            <a href="#" className="text-white me-3"><img src={wa} /></a>
                            <a href="#" className="text-white me-3"><img src={ins} /> </a>
                            <a href="#" className="text-white"><img src={tt} /></a>
                        </div>
                        <p>
                            <Row>
                                <Col md={1} className='p-0'>

                                    <FaMapMarkerAlt />
                                </Col>
                                <Col md={10}>

                                    Visiting hours:
                                    Office: 10:00 aM To 7:00 PM All Days chamber:
                                    10:00 AM TO 4:30 PM  expect for 2nd saturday and sunday
                                </Col>

                            </Row>


                        </p>


                    </Col>
                </Row>

                <Row className="text-center footer-last-headhing">
                    <Col>
                        <p>Made with love in India, powered by
                            <a href='https://www.squibfactory.com/' className='text-white'>

                                Squibfactory
                            </a>


                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
