import React from 'react'
import ExpertiseHeroSection from '../components/ExpertiseSections/ExpertiseHeroSection'
import LawSectionCards from '../components/ExpertiseSections/LawSectionCards'
import '../components/css/expertise.css';

const Expertise = () => {
    return (
        <div>

            <ExpertiseHeroSection />
            <LawSectionCards />
        </div>
    )
}

export default Expertise
